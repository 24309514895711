footer{
  margin-top:7.313rem !important;
}
.footer-container { 
  bottom: 0;
  left: 0;
  width: 100%;
  padding:1.25rem;
  background-color:#171717;
  color:#FCFCFC;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items: center;
  height:3.563rem;
  box-sizing: border-box; 
  padding:1.25rem;
  position:static;
}

.powered-by {
  display: flex;
  align-items:center;
  margin-left:11.25rem;
  margin-right:-0.313rem;
}
.powered-by a {
  color:#f7f7f7;  
  text-decoration: none;
}

.powered-by a:hover {
  color: yellow;
}

.footer-container h4 {
  margin-right:-0.625rem;
  font-weight: 400;
  font-size:1.125rem;
}
.footer-logo {
  width:12.5rem;
  height: auto;
  text-align: right;
}

@media (max-width: 480px) {
  footer {
    margin-bottom:2rem;
  }
  .footer-container {
    max-width:99%;
    padding:2rem;
    background-color:#FAFAFA;
    display:flex;
    flex-direction: column;
    margin-bottom:3rem;
  }

  .footer-container h4 {
   font-size:0.9rem;
   margin-top:0.7rem;
  }

  .footer-logo {
    width:12.5rem;
    height: auto;
  }

  .powered-by {
      display:flex;
      flex-direction:column;
      justify-content: center;
      text-align: center;
      margin:0.7rem 0.7rem;
  }
}
