
.form-group {
    margin-bottom:40px;
    width: 100%;
    color:#171717;
  }
  
  .form-group label {
    display: flex;
    text-align: start !important ;
    margin-bottom: 0.5rem;
    color:#171717;
    font-size: 1rem;
    font-weight: 600;
    justify-content: space-between!important;
  }
  
  .form-group input {
    width: 100%;
    padding:12px 20px;
    font-size: 1rem;
    border: 2px solid #EBEBEB;
    border-radius: 0.625rem;
    background-color: #FCFCFC;
    box-sizing: border-box;
   color:#171717; 
   height:auto;
   
  }

  .form-group select {
    width: 100%;
    padding:12px 20px;
    font-size: 1rem;
    border: 2px solid #EBEBEB;
    border-radius: 0.625rem;
    background-color: #FCFCFC;
    box-sizing: border-box;
   color:#171717; 
   height:auto;
  }
  .form-group textarea {
    width: 100%;
    padding:12px 20px;
    font-size: 1rem;
    border: 2px solid #EBEBEB;
    border-radius: 0.625rem;
    background-color: #FCFCFC;
    box-sizing: border-box;
   color:#171717; 
   height:auto;
  }

  
.edit-icon-outer {
    display: flex;
    align-items: center;
  }
  
  .edit-icon-outer h3 {
    font-size:14px;
    color:#171717;
    margin: 0;
    margin-right:05px; 
    text-decoration: underline;
    font-weight: 600;
  }
  
  .edit-icon-svg {
    width:17px;          
    height:17px;        
    cursor: pointer;       
    transition: transform 0.2s; 
  }
  
  .edit-icon-svg:hover {
    transform: scale(1.1);  
  }
  #smswhole-newtem{
    background-color:white;
    width:30.98%;
    height:auto;
    margin: 0 auto; 
    border-radius:10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding:40px;
    margin-top: 20px;
    margin-bottom: 20px
  }

  .sms-button {
margin-top:40px;
gap:21px;
display: flex;
flex-direction: row;
justify-content:center;
align-items: center;
margin-bottom:-30px;
  }

  .save-btn {
    width:100%;
    height:60px;
    font-size:16px;
    border-radius:10px;
    font-weight: 700;
    color:#171717;
    background-color:#FFD966;
    border:none
  }
  .save-btn:hover {
    color:white;
    background-color:#171717;
  }

  
  .cancel-btn {
    width:100%;
    height:60px;
    font-size:16px;
    border-radius:10px;
    font-weight: 700;
    color:#171717;
    background-color:white;
    border:1px solid #171717;
  }

  
  .del-btn {
    width:25.08%;
    height:60px;
    font-size:16px;
    border-radius:10px;
    font-weight: 700;
    color:#171717;
    background-color:white;
    border:1px solid #171717;
  }
  .del-btn:hover {
    background:#FFF5F5;
  }
  .cancel-btn:hover {
    color:white;
    background-color:#171717;
  }

  .delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:9999;
  }
  
  
  .delete-popup-content {
    background: white;
    padding:50px;
    border-radius: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
  
  .delete-popup-content p {
    margin-top:1rem;
  }
  .popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top:1rem;
    flex-direction: row;
    gap:2rem;
  }
  
  .delete-confirm-btn {
    background: red;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius:10px;
    width:175px;
    height:60px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .cancel-confirm-btn {
    background:none;
    color:#171717;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius:10px;
    width:175px;
    height:60px;
    font-weight: bold;
    font-size: 16px;
   border:2px solid #171717;
  }
  
 @media screen and (max-width: 600px) { 
  .form-group {
    margin-bottom: 20px; 
  }

  .form-group label {
    font-size: 0.875rem; 
  }

  .form-group input, 
  .form-group select, 
  .form-group textarea {
    padding: 10px 15px; 
    font-size: 0.875rem; 
  }

  #smswhole-newtem {
    width: 90%; 
    padding: 20px; 

  }

  .sms-button {
    flex-direction: column; 
    align-items: center; 
    gap: 1rem; 
    margin-bottom: 0; 
  }

  .save-btn, 
  .cancel-btn, 
  .del-btn {
    width: 100%; 
    margin: 0; 
    height: 50px; 
    font-size: 14px; 
  }

  .popup-buttons {
    flex-direction: column; 
    gap: 1rem; 
  }

  .delete-popup-content {
    padding: 30px; 
  }
}

@media screen and (min-width: 601px) and (max-width: 1440px) { 
  .form-group {
    margin-bottom: 30px; 
  }

  #smswhole-newtem {
    width: 50%; 
    padding: 30px; 
  }


  .save-btn, 
  .cancel-btn, 
  .del-btn {
    width: 45%; 
    height: 55px; 
    font-size: 15px; 
    margin: 0; 
  }

  .sms-button {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center; 
    gap: 1.5rem; 
    margin: 0; 
  }

  .popup-buttons {
    display: flex;
    flex-direction: row; 
    gap: 1.5rem; 
    justify-content: center;
    align-items: center; 
  }

  .delete-popup-content {
    padding: 40px; 
  }
}
