

.dashboard {
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 75vh;
  overflow-y: auto;
  background-color: #171717;
  color: white;
  padding: 20px 10px;
  position:fixed;
  z-index: 100;
  transition: width 0.5s ease-in-out;
  padding-left: 20px;
  padding-right: 13px;
  padding-top: 5px;
  border-radius: 25px;
  top: 150px;
  left: 30px;
  display: block;
  border: 2px solid #171717;
  margin-bottom:2rem !important;

}


.dashboard.expanded {
  width: 260px;
}

.dashboard ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.dashboard li {
  position: relative;
  margin-top: 40px;
  margin-bottom: 5px;
}

.dropdown li a,
ul li a {
  color: white;
  text-decoration: none;
  display: block;
}

.dashboard li {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dashboard li:hover {
  background-color: none;
}

.dashboard-icon-container {
  display: flex;
  align-items: center;
  
}

.hometext-style {
  display: flex;
  align-items: center;
}

#homeborder {
  width: 100%;
}

.hometext {
  display: flex;
  align-items: center;
  color: white;
}
.hometext.enabled {
  color: black;
}

.homeicon {
  width: 22px;
  height: 22px;
  margin-left: 2px;
}

.hometext p {
  margin-left: 10px;
  vertical-align: middle;
  color: inherit;
}

.hometext-style.enabled,
.hometext-style.disabled {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: -12px;
  margin-bottom: -10px;
}

.hometext-style.enabled p {
  color: #000000;
}

.hometext-style.enabled {
  background-color: #FFD966;
  border-radius: 8px;
  font-weight: bold;
  text-decoration: none;
  color: #171717;
  width: 98%;
}

.hometext-style.disabled {
  text-decoration: none;
  color: #171717;
}

.dashboard li span {
  margin-left: 10px;
  display: none;
}

.dashboard.expanded li span {
  display: inline;
}

.dashboard-icon {
  width: 22px;
  height: 22px;
}

.dropdown-icon {
  width: 22px;
  height: 22px;
  position: relative;
  right: -120px;
  top: 0;
  transition: transform 0.3s;
}

.dropdown-iconaadh {
  width: 22px;
  height: 22px;
  position: relative;
  right: -92px;
  top: 0;
  transition: transform 0.3s;
  margin-bottom: 2rem;
}

.priceupdate-style {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 0 0 10px;
}

.priceupdate-style.enabled {
  background-color: #FFD966;
  border-radius: 8px;
  font-weight: bold;
  text-decoration: none;
  color: #171717;
  width: 98%;
  height: 40px;
  padding: 0 0 0 15px;
  display: flex;
  align-items: center;
  margin-left: -12px;
}

.priceupdate-style.enabled #priceup {
  color: #000000;
}

.priceupdatetext {
  display: flex;
  align-items: center;
  margin-left: -5px;
  color: white;
}

.pricejewel-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  margin-left: 0px;
}

#priceupdatebox {
  width: 100%;
}

.priceupdate-style.disabled {
  text-decoration: none;
  color: #171717;
  margin-left: -5px;
}

.dashboard-icon-container-template {
  margin-top:4px !important;
  display: flex;
  align-items: center;
  margin-bottom:16px;
}

.nav-list-outer {
  border-left: 2px solid white;
    overflow: hidden;
  padding-left:20px;
  margin-left:-18px;
} 
.pan360-link {
  position: relative;
}

.pan360-link::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 2px; 
  background-color: white; 
  transform: translateX(-50%);
}


.dropdown-icon-template {
  transition: transform 0.3s ease; 
}

img.dropdown-icon-template {
  margin-left:69%;
}

.menu-item .dropdown li a {
  padding: 10px;
  width:auto;
  margin-top:-35px!important;
  border-radius:10px;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  position: fixed;
  display: flex;
}
.menu-item .dropdown {
  position: absolute;
  top: 100%;
  left: 48%;
  transform: translateX(-50%);
  min-width:170px; 
  z-index: 100;
  display: block;
}


@media (max-width: 768px) {
  .dashboard {
    display: none; /* Hide sidebar by default */
    position: absolute;
    top: 0;
    left: 0;
    width: 250px; /* Adjust width as needed */
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .dashboard.expanded {
    display: block; /* Show sidebar when expanded */
  }
}

/* Toggle button for mobile view */
.toggle-button {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1001;
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
}
@media (max-width: 480px) {
  .dashboard {
    display: none;
  }

  .bottom-navigation {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color:#171717 !important;
    z-index: 1;
  }

  .bottom-navigation .MuiBottomNavigationAction-root {
    color:white;
  }

  .bottom-navigation .Mui-selected {
    color: #FFD966 !important;
  }
}

