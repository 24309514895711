#manage-template-outer-cont{
    background-color:white;
    width:720px !important;
    height:auto;
    margin: 0 auto; 
    border-radius:10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding:40px;
    margin-top: 20px;
    margin-bottom: 20px
  }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

  .date-out-cont {
    display: flex;
    flex-direction: row;
    gap:32px;
  }

  .select-date-out label{
    display: flex;
    text-align: left;
    font-weight:600;
    margin-bottom:8px;
  }
  .select-date-cont input{
    width:186px;
    height:50px;
    background-color:#FAFAFA;
    margin-bottom:16px;
    border:1px solid #E6E6E6;
    border-radius:10px;
    padding:0 16px;
    font-size:16px;
    color:#171717;
  }
  .add-missed {
    text-align: left;
    font-weight:bold;
    font-size:18px;
    margin-bottom:16px;
  }
  .discard-save-out-cont {
    display: flex;
    flex-direction: row;
    gap:24px;
     }
  .save-button-sms {
    width:100%;
    background-color: #FFD966;
    color:#FFFFFF;
    border-radius:10px;
    height:60px;
    font-size: 16px;
    font-weight: bold;
    border:none;
    color:#171717;
  }
  .save-button-sms:hover {
    width:100%;
    background-color:#171717;
    color:#FFFFFF;
    border-radius:10px;
    height:60px;
    font-size: 16px;
    font-weight: bold;
  }


  .discard-button-sms {
    width:100%;
    background-color:#FFFFFF;
    color:#171717;
    border-radius:10px;
    height:60px;
    font-size: 16px;
    border:2px solid #171717;
    font-weight: bold;
  }

  
  .discard-button-sms:hover {
    width:100%;
    background-color:#171717;
    color:#FFFFFF;
    border-radius:10px;
    height:60px;
    font-size: 16px;
    border:2px solid #171717;
    font-weight: bold;
  }