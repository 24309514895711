.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 23, 23, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /*
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /*animation: spin 1s linear infinite; */
 /* }  */
   
 .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo-popup {
    width: 350px;
    height:250px;
    object-fit: contain; 
  }